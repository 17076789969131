//utils/kindleHandler.ts

import axios from 'axios';

const API_ENDPOINT = 'https://ko529rjosk.execute-api.us-east-1.amazonaws.com/default/readlinker-kindle-handler-main-open';

// idTokenをオプショナルに変更
export async function callGetApi(operation: string, params: any, idToken?: string) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }
  
    try {
      const response = await axios.get(API_ENDPOINT, {
        params: {
          OperationType: operation,
          ...params
        },
        headers
      });
      return response.data;
    } catch (error) {
      console.error(`API call failed: ${error}`);
      throw error;
    }
}
  
export async function callPostApi(operation: string, data: any, idToken?: string) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }
  
    try {
      const response = await axios.post(API_ENDPOINT, 
        {
          Operation: operation,
          ...data
        },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(`API call failed: ${error}`);
      throw error;
    }
}

// Kindle本のタイプ定義
export interface KindleBook {
  asin: string;
  userSub: string;
  updatedAt: number;
  acquiredDate?: string;
  acquiredTime?: number;
  isGift?: boolean;
  isPurchased?: boolean;
  originType?: string;
  title: string;
  authors?: string;
  category?: string;
  image_url?: string;
  product_url?: string;
  last_updated: number;
  created_at: number;
  isDeleted: boolean;
}

// レスポンスタイプの定義
export interface KindleBooksResponse {
  books: KindleBook[];
  // count: number;
}

/**
 * ユーザーのKindle本一覧を取得する
 * @param userSub ユーザーのサブID
 * @param idToken 認証トークン
 * @returns Kindle本のリスト
 */
export async function getUserKindleBooks(userSub: string, idToken: string): Promise<KindleBook[]> {
  try {
    const response = await callPostApi('getUsersKindleBooks', { userSub }, idToken);
    console.log('Kindle books:', response);
    return response.books as KindleBook[];
  } catch (error) {
    console.error('Failed to fetch Kindle books:', error);
    throw error;
  }
}

/**
 * 特定のASINに基づいてKindle本の詳細を取得する
 * @param asins ASIN配列
 * @param idToken 認証トークン
 * @returns Kindle本の詳細情報
 */
export async function getKindleBooksDetail(asins: string[], idToken: string) {
  try {
    const response = await callPostApi('getKindleBooksDetail', { asins }, idToken);
    return response;
  } catch (error) {
    console.error('Failed to fetch Kindle book details:', error);
    throw error;
  }
}

// /**
//  * ユーザーのKindle本を更新または追加する
//  * @param kindleBooks 更新または追加するKindle本データ
//  * @param idToken 認証トークン
//  * @returns 操作の結果
//  */
// export async function updateUserKindleBooks(kindleBooks: Partial<KindleBook>[], idToken: string) {
//   try {
//     const response = await callPostApi('updateUserKindleBooks', { kindleBooks }, idToken);
//     return response;
//   } catch (error) {
//     console.error('Failed to update Kindle books:', error);
//     throw error;
//   }
// }

// /**
//  * Kindle本をユーザーのライブラリから削除する（論理削除）
//  * @param asins 削除するKindle本のASIN配列
//  * @param userSub ユーザーのサブID
//  * @param idToken 認証トークン
//  * @returns 操作の結果
//  */
// export async function removeUserKindleBooks(asins: string[], userSub: string, idToken: string) {
//   try {
//     const response = await callPostApi('removeUserKindleBooks', { asins, userSub }, idToken);
//     return response;
//   } catch (error) {
//     console.error('Failed to remove Kindle books:', error);
//     throw error;
//   }
// }

// /**
//  * ユーザーのKindleデータ最終更新日時を取得する
//  * @param userSub ユーザーのサブID
//  * @param idToken 認証トークン
//  * @returns 最終更新日時情報
//  */
// export async function getLastUpdateTimestamp(userSub: string, idToken: string) {
//   try {
//     const response = await callPostApi('getLastUpdateTimestamp', { userSub }, idToken);
//     return response;
//   } catch (error) {
//     console.error('Failed to fetch last update timestamp:', error);
//     throw error;
//   }
// }
