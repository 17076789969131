// utils/getUserSub.ts

import { userPool } from '../services/cognitoConfig';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

// userSubを取得する関数
export const getUserSub = (): Promise<string | null> => {
  const cognitoUser: CognitoUser | null = userPool.getCurrentUser();

  if (!cognitoUser) {
    // 現在のユーザーが存在しない場合は null を返す
    return Promise.resolve(null);
  }

  return new Promise<string | null>((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        console.error('Error getting session:', err);
        reject(null);
      } else if (session) {
        const userSub = session.getIdToken().payload.sub;
        resolve(userSub);
      } else {
        resolve(null);
      }
    });
  });
};

//userNameを取得する関数
export const getUserName = (): Promise<string | null> => {
  const cognitoUser: CognitoUser | null = userPool.getCurrentUser();

  if (!cognitoUser) {
    // 現在のユーザーが存在しない場合は null を返す
    return Promise.resolve(null);
  }

  return new Promise<string | null>((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        console.error('Error getting session:', err);
        reject(null);
      } else if (session) {
        const userName = session.getIdToken().payload['cognito:username'];
        resolve(userName);
      } else {
        resolve(null);
      }
    });
  });
};