//ListManagementPage.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import {     
    getBooksInListDetail_ListOfBookList, 
    updateListOfBookList, 
    deleteBooksInList, 
    moveBooksInList,
    deleteListOfBookList,
    addListOfBookList,
    getListOfBookList,
    addBooksInList
} from '../utils/listHandler';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';

interface BookInList {
    userSub: string;
    bookListID: string;
    KANRI_ID: string;
    addAt: number;
}

interface BookList {
    userSub: string;
    bookListID: string;
    listName: string;
    comments: string;
    openLevel: string;
    listOrder: number;
    addAt: number;
}

interface BookDetail {
    KANRI_ID: string;
    MainTitle: string;
    ISBN: string;
    Responsibility: string;
    PublishingDate: string;
}

const ListManagementPage: React.FC = () => {
    const [booksInList, setBooksInList] = useState<BookInList[]>([]);
    const [bookLists, setBookLists] = useState<BookList[]>([]);
    const [bookDetails, setBookDetails] = useState<{ [key: string]: BookDetail }>({});
    const [selectedList, setSelectedList] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { idToken, userSub } = useAuth();
    const navigate = useNavigate();

    const [editingList, setEditingList] = useState<BookList | null>(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedBook, setSelectedBook] = useState<string | null>(null);
    const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState<null | HTMLElement>(null);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deletingListId, setDeletingListId] = useState<string | null>(null);
    const [deletingListName, setDeletingListName] = useState<string>('');

    const [openCreateListDialog, setOpenCreateListDialog] = useState(false);
    const [newListName, setNewListName] = useState('気になる本');
    const [newListOpenLevel, setNewListOpenLevel] = useState('PUBLIC');
    const [isCreatingList, setIsCreatingList] = useState(false);
    

    const fetchListData = useCallback(async () => {
        if (!idToken || !userSub) return;
    
        setIsLoading(true);
        setError(null);
    
        try {
            const response = await getBooksInListDetail_ListOfBookList(idToken);
            const sortedListOfBookListTable = response.listOfBookListTable.sort((a: { listOrder: number }, b: { listOrder: number }) => a.listOrder - b.listOrder);
            
            setBooksInList(response.booksInListTable || []); // booksInListTableがundefinedの場合、空配列を設定
            setBookLists(sortedListOfBookListTable);
    
            // console.log(booksInList);
            // console.log(bookLists);
            
            const detailsMap: { [key: string]: BookDetail } = {};
            if (Array.isArray(response.book_details)) {
                response.book_details.forEach((book: BookDetail) => {
                    detailsMap[book.KANRI_ID] = book;
                });
            } else {
                // console.warn('book_details is not an array:', response.book_details);
            }
            setBookDetails(detailsMap);
    
            if (sortedListOfBookListTable.length > 0) {
                setSelectedList(sortedListOfBookListTable[0].bookListID);
            }
        } catch (err) {
            setError('リストデータの取得中にエラーが発生しました。');
            // console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [idToken, userSub]);
    

    useEffect(() => {
        fetchListData();
    }, [fetchListData]);

    // 各リストの冊数を計算
    const listBookCounts = useMemo(() => {
        const counts: { [key: string]: number } = {};
        bookLists.forEach(list => {
            counts[list.bookListID] = 0; // 初期値を0に設定
        });
        booksInList.forEach(book => {
            if (counts[book.bookListID] !== undefined) {
                counts[book.bookListID]++;
            }
        });
        return counts;
    }, [booksInList, bookLists]);

    // 選択されたリストの本を addAt で並び替える
    const sortedSelectedListBooks = useMemo(() => {
        if (!selectedList) return [];
        return booksInList
            .filter(book => book.bookListID === selectedList)
            .sort((a, b) => b.addAt - a.addAt); // 降順（新しい順）で並び替え
    }, [booksInList, selectedList]);


    const handleListClick = (listId: string) => {
        setSelectedList(listId);
    };

    const handleBookClick = (kanriId: string) => {
        window.open(`/manifestation/${kanriId}`, '_blank');
    };

    const handleEditList = (listId: string) => {
        const list = bookLists.find(l => l.bookListID === listId);
        if (list) {
            setEditingList(list);
            setIsEditDialogOpen(true);
        }
    };

    const handleEditDialogClose = () => {
        setIsEditDialogOpen(false);
        setEditingList(null);
    };

    const handleEditListSubmit = async () => {
        if (!editingList || !idToken) return;

        try {
            await updateListOfBookList(
                editingList.bookListID,
                editingList.listName,
                editingList.comments,
                editingList.openLevel,
                editingList.listOrder,
                idToken
            );
            setSnackbarMessage('リストが更新されました');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            fetchListData(); // リストデータを再取得
        } catch (error) {
            // console.error('Error updating list:', error);
            setSnackbarMessage('リストの更新に失敗しました');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }

        handleEditDialogClose();
    };

    const handleShareList = (listId: string) => {
        const list = bookLists.find(l => l.bookListID === listId);
        if (list && (list.openLevel === 'PUBLIC' || list.openLevel === 'URLSHARE')) {
            // ここで共有ロジックを実装 (例: URLをクリップボードにコピー)
            const shareUrl = `https://readlinker.com/list/${listId}`;
            navigator.clipboard.writeText(shareUrl);
            setSnackbarMessage('共有URLがコピーされました');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        }
    };

    const handleBookOptionsClick = (event: React.MouseEvent<HTMLButtonElement>, bookId: string) => {
        event.stopPropagation(); 
        setAnchorEl(event.currentTarget);
        setSelectedBook(bookId);
    };

    const handleBookOptionsClose = () => {
        setAnchorEl(null);
        setSelectedBook(null);
    };

    const handleRemoveFromList = async () => {
        if (!selectedBook || !selectedList || !idToken) return;
        
        try {
            await deleteBooksInList(selectedList, selectedBook, idToken);
            setSnackbarMessage('本がリストから削除されました');
            setSnackbarSeverity('success');
            fetchListData(); // リストデータを再取得
        } catch (error) {
            // console.error('Error removing book from list:', error);
            setSnackbarMessage('本の削除に失敗しました');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
        handleBookOptionsClose();
    };

    const handleMoveToListClick = (event: React.MouseEvent<HTMLElement>) => {
        setMoveMenuAnchorEl(event.currentTarget);
    };

    const handleMoveMenuClose = () => {
        setMoveMenuAnchorEl(null);
    };

    const handleMoveToList = async (targetListId: string) => {
        if (!selectedBook || !selectedList || !idToken) return;
        
        try {
            const response = await moveBooksInList(selectedList, selectedBook, targetListId, idToken);
            if (response.statusCode === 200) {
                setSnackbarMessage('別のリストに移動しました');
                setSnackbarSeverity('success');
            } else if (response.statusCode === 409) {
                setSnackbarMessage('移動先リストに重複があったため先頭へ移動しました');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage('エラーが発生しました');
                setSnackbarSeverity('error');
            }
            fetchListData(); // リストデータを再取得
        } catch (error) {
            // console.error('Error moving book to another list:', error);
            setSnackbarMessage('本の移動に失敗しました');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
        handleBookOptionsClose();
        handleMoveMenuClose();
    };
    


    if (isLoading) {
        return (
            <Box sx={{ width: '100%', mt: 4 }}>
                <Typography variant="h6" align="center" gutterBottom>
                    リストを読み込んでいます...
                </Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    const handleDeleteListClick = (listId: string) => {
        const listToDelete = bookLists.find(list => list.bookListID === listId);
        if (listToDelete) {
            setDeletingListId(listId);
            setDeletingListName(listToDelete.listName);
            setDeleteConfirmOpen(true);
        }
    };

    const handleDeleteConfirm = async () => {
        if (!deletingListId || !idToken || !userSub) return;

        try {
            const response = await deleteListOfBookList(userSub, deletingListId, idToken);
            if (response.ResponseMetadata.HTTPStatusCode !== 200) {
                setSnackbarMessage('リストの削除に失敗しました');
                setSnackbarSeverity('error');
            } else {
                setSnackbarMessage('リストを削除しました');
                setSnackbarSeverity('success');
                fetchListData(); // リストデータを再取得
            }
        } catch (error) {
            // console.error('Error deleting list:', error);
            setSnackbarMessage('リストの削除に失敗しました');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
        setDeleteConfirmOpen(false);
        setDeletingListId(null);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setDeletingListId(null);
    };

    const handleCreateNewList = () => {
        handleListClose(); // メニューを閉じる
        setOpenCreateListDialog(true); // 新しいリスト作成ダイアログを開く
      };
    
      const handleCloseCreateListDialog = () => {
        setOpenCreateListDialog(false);
        setNewListName('気になる本');
        setNewListOpenLevel('PUBLIC');
      };

    const handleCreateList = async () => {
        setIsCreatingList(true);
        if (!idToken || !userSub) return;
        const newListOrder = bookLists.length > 0 ? Math.max(...bookLists.map(list => list.listOrder)) + 1 : 1;
        try {
            await addListOfBookList(newListName, '', newListOpenLevel, newListOrder, idToken);
            setSnackbarMessage('リストが作成されました');
            setSnackbarSeverity('success');
            // Re-fetch the lists
            await fetchBookLists();
        } catch (error) {
            setSnackbarMessage('リストの作成に失敗しました');
            setSnackbarSeverity('error');
        } finally {
            setIsCreatingList(false);
            setSnackbarOpen(true);
            setOpenCreateListDialog(false);
        }
    };

    
    const handleListClose = () => {
        setAnchorEl(null);
    };

    const fetchBookLists = async () => {
        if (idToken) {
          try {
            const lists = await getListOfBookList(idToken);
            setBookLists(lists.sort((a: any, b: any) => a.listOrder - b.listOrder));
          } catch (error) {
            // console.error('Error fetching book lists:', error);
          }
        }
      };
    
    //   useEffect(() => {
    //     fetchBookLists();
    //   }, [idToken]);

    return (
    <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" gutterBottom>リスト管理</Typography>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleCreateNewList}
            >
                ＋リストを新規作成
            </Button>
        </Box>
        {bookLists.length === 0 ? (
    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        リストがありません。新しいリストを作成してください。
    </Typography>
) : (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '250px', mr: 2, flexShrink: 0 }}>
                    <List>
                        {bookLists.map((list) => (
                            <ListItem 
                                key={list.bookListID} 
                                disablePadding 
                                secondaryAction={
                                    <>
                                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditList(list.bookListID)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteListClick(list.bookListID)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="share" 
                                            onClick={() => handleShareList(list.bookListID)}
                                            disabled={list.openLevel === 'PRIVATE'}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                    </>
                                }
                            >
                                <ListItemButton
                                    selected={selectedList === list.bookListID}
                                    onClick={() => handleListClick(list.bookListID)}
                                >
                                    <ListItemText 
                                        primary={list.listName}
                                        secondary={
                                            <>
                                                <Typography component="span" variant="body2">
                                                    {`${listBookCounts[list.bookListID] || 0} 冊`}
                                                </Typography>
                                                <br />
                                                <Typography component="span" variant="body2" color="textSecondary">
                                                    {list.openLevel === 'PUBLIC' ? '公開' : 
                                                    list.openLevel === 'URLSHARE' ? 'リンク共有' : '非公開'}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    {selectedList && (
                        <>
                            <Typography variant="body1" gutterBottom>
                                {bookLists.find(list => list.bookListID === selectedList)?.comments}
                            </Typography>
                            <Grid container spacing={2} columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}>
                                {sortedSelectedListBooks.map((book) => {
                                    const bookDetail = bookDetails[book.KANRI_ID];
                                    if (!bookDetail) return null;
                                    return (
                                        <Grid item xs={1} key={book.KANRI_ID}>
                                            <Card 
                                                sx={{ 
                                                    height: '220px',
                                                    display: 'flex', 
                                                    flexDirection: 'column',
                                                    transition: '0.3s',
                                                    '&:hover': {
                                                        boxShadow: 10,
                                                    },
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    sx={{
                                                        height: 180,
                                                        objectFit: 'contain',
                                                    }}
                                                    image={generateCoverUrl(bookDetail)}
                                                    alt={bookDetail.MainTitle}
                                                    onClick={() => handleBookClick(book.KANRI_ID)}
                                                />
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'flex-end', 
                                                    p: 1 
                                                }}>
                                                    <IconButton 
                                                        size="small"
                                                        onClick={(e) => handleBookOptionsClick(e, book.KANRI_ID)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Box>
                                            </Card>
                                            <Box sx={{ mt: 1, textAlign: 'left' }}>
                                                <Typography variant="subtitle2" noWrap>{bookDetail.MainTitle}</Typography>
                                                <Typography variant="body2" color="text.secondary" noWrap>
                                                    {bookDetail.Responsibility}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary" noWrap>
                                                    {bookDetail.PublishingDate}
                                                </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>
)}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleBookOptionsClose}
            >
                <MenuItem onClick={handleRemoveFromList}>リストから削除</MenuItem>
                <MenuItem onClick={handleMoveToListClick}>他のリストへ移動</MenuItem>
            </Menu>

            {/* 移動先リスト選択メニュー */}
            <Popover
                open={Boolean(moveMenuAnchorEl)}
                anchorEl={moveMenuAnchorEl}
                onClose={handleMoveMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {bookLists
                        .filter(list => list.bookListID !== selectedList)
                        .map((list) => (
                            <MenuItem 
                                key={list.bookListID} 
                                onClick={() => handleMoveToList(list.bookListID)}
                            >
                                {list.listName}
                            </MenuItem>
                        ))}
                </List>
            </Popover>

            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">リストの削除確認</DialogTitle>
                <DialogContent>
                    <DialogContent id="alert-dialog-description">
                        {deletingListName} を削除してもよろしいですか？
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>キャンセル</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        削除
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
                <DialogTitle>リストを編集</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="リスト名"
                        type="text"
                        fullWidth
                        value={editingList?.listName || ''}
                        onChange={(e) => setEditingList(prev => prev ? {...prev, listName: e.target.value} : null)}
                    />
                    <TextField
                        margin="dense"
                        label="説明"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={editingList?.comments || ''}
                        onChange={(e) => setEditingList(prev => prev ? {...prev, comments: e.target.value} : null)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>公開設定</InputLabel>
                        <Select
                            value={editingList?.openLevel || ''}
                            onChange={(e) => setEditingList(prev => prev ? {...prev, openLevel: e.target.value} : null)}
                        >
                            <MenuItem value="PUBLIC">公開</MenuItem>
                            <MenuItem value="URLSHARE">リンク共有</MenuItem>
                            <MenuItem value="PRIVATE">非公開</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>キャンセル</Button>
                    <Button onClick={handleEditListSubmit}>保存</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCreateListDialog} onClose={handleCloseCreateListDialog}>
                <DialogTitle>新しいリストを作成</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="リスト名"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="open-level-label">公開範囲</InputLabel>
                    <Select
                    labelId="open-level-label"
                    id="open-level"
                    value={newListOpenLevel}
                    label="公開範囲"
                    onChange={(e) => setNewListOpenLevel(e.target.value)}
                    >
                    <MenuItem value="PUBLIC">公開</MenuItem>
                    <MenuItem value="URLSHARE">シェア</MenuItem>
                    <MenuItem value="PRIVATE">非公開</MenuItem>
                    </Select>
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseCreateListDialog} disabled={isCreatingList}>キャンセル</Button>
                <Button onClick={handleCreateList} disabled={isCreatingList || !newListName.trim()}>
                    {isCreatingList ? '作成中...' : '作成'}
                </Button>
                </DialogActions>
            </Dialog>


            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ListManagementPage;