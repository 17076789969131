import { FollowFriendInfo, UserDetail, FollowersCountResponse, FollowingCountResponse, FriendsCountResponse } from "../types/types";
import axios from 'axios';

const API_URL = 'https://jbk4lskje4.execute-api.us-east-1.amazonaws.com/default/readlinker-get-follow_info';

interface FollowingResponse {
  following: UserDetail[];
  lastEvaluatedKey: string | null;
}

interface FollowerResponse {
  followers: UserDetail[];
  lastEvaluatedKey: string | null;
}

interface FriendsResponse {
  friends: UserDetail[];
  lastEvaluatedKey: string | null;
}

const makeApiRequest = async (operation: string, targetUserSub: string, idToken?: string, lastEvaluatedKey?: string | null) => {
  const params = new URLSearchParams({
    operation,
    targetUserSub,
  });

  if (lastEvaluatedKey) {
    params.append('lastEvaluatedKey', lastEvaluatedKey);
  }

  const headers: { [key: string]: string } = {};

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;
  }

  try {
    const response = await axios.get(`${API_URL}?${params.toString()}`, { headers });
    // console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(`Failed to ${operation}:`, error.response?.data || error.message);
    } else {
      console.error(`Failed to ${operation}`, error);
    }
    return null;
  }
};

export const get_followers_count = async (targetUserSub: string, idToken?: string): Promise<FollowersCountResponse | null> => {
  return makeApiRequest('get_followers_count', targetUserSub, idToken);
};

export const get_followers_details = async (targetUserSub: string, idToken?: string, lastEvaluatedKey?: string | null): Promise<FollowerResponse | null> => {
  return makeApiRequest('get_followers_details', targetUserSub, idToken, lastEvaluatedKey);
};

export const get_following_count = async (targetUserSub: string, idToken?: string): Promise<FollowingCountResponse | null> => {
  return makeApiRequest('get_following_count', targetUserSub, idToken);
};

export const get_following_details = async (targetUserSub: string, idToken?: string, lastEvaluatedKey?: string | null): Promise<FollowingResponse | null> => {
  return makeApiRequest('get_following_details', targetUserSub, idToken, lastEvaluatedKey);
};

export const get_friends_count = async (targetUserSub: string, idToken?: string): Promise<FriendsCountResponse | null> => {
  return makeApiRequest('get_friends_count', targetUserSub, idToken);
};

export const get_friends_details = async (targetUserSub: string, idToken?: string, lastEvaluatedKey?: string | null): Promise<FriendsResponse | null> => {
  return makeApiRequest('get_friends_details', targetUserSub, idToken, lastEvaluatedKey);
};

export const get_mutual_friends = async (targetUserSub: string, idToken: string, lastEvaluatedKey?: string | null): Promise<FriendsResponse | null> => {
  return makeApiRequest('get_mutual_friends', targetUserSub, idToken, lastEvaluatedKey);
};

export const get_follow_friend_info = async (targetUserSub: string, idToken?: string): Promise<FollowFriendInfo | null> => {
  try {
    const [followersCount, followingCount, friendsCount] = await Promise.all([
      get_followers_count(targetUserSub, idToken),
      get_following_count(targetUserSub, idToken),
      get_friends_count(targetUserSub, idToken)
    ]);

    if (followersCount && followingCount && friendsCount) {
      return {
        following_sum: followingCount.followingCount,
        follower_sum: followersCount.followersCount,
        friends_sum: friendsCount.friendsCount
      };
    }
    return null;
  } catch (error) {
    console.error('Failed to get follow friend info', error);
    return null;
  }
};