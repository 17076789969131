//UsersBookshelf.tsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import BookDetailDialog from '../components/BookDetailDialog';
import axios from 'axios';
import { getUserSub, getUserName } from '../utils/getUserSub';
import { getUserSubFromUsernameOpenEndpoint } from '../utils/getUserSubFromUsername';
import LinearProgress from '@mui/material/LinearProgress';
import { getUserKindleBooks,KindleBook } from '../utils/kindleHandler';
import { getIdToken } from '../utils/getIdToken';

interface Shelf {
  HondanaName: string;
  GlobalHondanaID: string;
  ColorCode: string;
  ShelfOrder: number;
}

interface Book {
  global_book: {
    GlobalBookID: string;
    KANRI_ID: string;
    GlobalHondanaID: string;
    BookOpenLevel: string;
    RegisteredDate: string;
    RemovedDate: string | null;
  };
  book_details: {
    MainTitle: string;
    ISBN: string;
    Responsibility: string;
    PublishingDate: string;
  };
  global_hondana: {
    GlobalHondanaID: string;
    HondanaName: string;
    ColorCode: string;
    OpenLevel: string;
    UserSub: string;
    ShelfOrder: number;
    Description: string | null;
  };
  lending_status: string;
}




const UsersBookshelf: React.FC = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [shelves, setShelves] = useState<Shelf[]>([]);
  const [selectedShelf, setSelectedShelf] = useState<string>("all");
  const [sortCriteria, setSortCriteria] = useState<string>("newest");
  const [filterText, setFilterText] = useState<string>('');
  const { idToken } = useAuth();
  const [selectedBookID, setSelectedBookID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // 通常の本の総冊数（Kindleの本は含まれない）
  const [totalBooks, setTotalBooks] = useState<number>(0);
  // Kindle本のデータ
  const [kindleBooks, setKindleBooks] = useState<KindleBook[]>([]);

  // 通常の本データ取得
  const fetchUserBookshelfData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const sub = await getUserSub();
    if (!sub) {
      setError('Failed to fetch user information');
      return;
    }
    try {
      const apiEndpoint = 'https://zejgdrpbj1.execute-api.us-east-1.amazonaws.com/v1/readlinker-book-handler-speed';
      const headers: { [key: string]: string } = {};
      if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
      }
      const response = await axios.get(apiEndpoint, {
        headers,
        params: {
          OperationType: 'fetch_GlobalBooks_Lendinginfo_ByUsersub_2',
          UserSub: sub
        }
      });
      if (response.status === 200) {
        const data = response.data;
        setTotalBooks(data.total_books);
        const allBooks: Book[] = [];
        for (const url of data.file_urls) {
          const fileResponse = await axios.get(url);
          allBooks.push(...fileResponse.data);
        }
        setBooks(allBooks);
        // createShelvesFromBooks(allBooks);
      } else {
        setError('Failed to fetch bookshelf data');
      }
    } catch (err) {
      setError('An error occurred while fetching the bookshelf data');
    } finally {
      setIsLoading(false);
    }
  }, [idToken]);

  // Kindleの本データ取得（レスポンスの型に合わせる
  const fetchKindleBooksData = useCallback(async () => {
    const userSub = await getUserSub();
    const idToken = getIdToken();
    try {
      const kindleData = await getUserKindleBooks(userSub!, idToken!);
    
      if (kindleData) {
        setKindleBooks(kindleData);
      } else {
        setKindleBooks([]);
      }
    } catch (error) {
      console.error("Error fetching kindle books:", error);
    }
  }, []);
  
useEffect(() => {
    // 1. 棚情報を格納する Map を用意
    const shelvesMap = new Map<string, Shelf>();

    // 2. 「すべての本」棚を追加
    shelvesMap.set("all", {
      HondanaName: "すべての紙の本",
      GlobalHondanaID: "all",
      ColorCode: "#808080",
      ShelfOrder: -1
    });

    // 3. 紙の本の棚を追加
    books.forEach(book => {
      // 除籍済みでない本だけ
      if (book.global_book.RemovedDate === null) {
        const { GlobalHondanaID, HondanaName, ColorCode, ShelfOrder } = book.global_hondana;
        if (GlobalHondanaID && !shelvesMap.has(GlobalHondanaID)) {
          shelvesMap.set(GlobalHondanaID, {
            GlobalHondanaID,
            HondanaName,
            ColorCode: ColorCode || "#FFFFFF",
            ShelfOrder: typeof ShelfOrder === 'number' ? ShelfOrder : 999
          });
        }
      }
    });

    // 4. Kindleの本があれば「Kindleの本」棚を追加
    console.log("kindleBooks:", kindleBooks);
    if (kindleBooks && kindleBooks.length > 0) {
      // まだ棚が存在しない場合のみ追加
      if (!shelvesMap.has("kindle")) {
        shelvesMap.set("kindle", {
          GlobalHondanaID: "kindle",
          HondanaName: "Kindleの本",
          ColorCode: "#DAA520",  // お好みの色
          ShelfOrder: 9999
        });
      }
    }

    // 5. 棚リストをソートして State に反映
    const newShelves = Array.from(shelvesMap.values()).sort((a, b) => {
      // "all" は常に先頭に
      if (a.GlobalHondanaID === "all") return -1;
      if (b.GlobalHondanaID === "all") return 1;
      return a.ShelfOrder - b.ShelfOrder;
    });

    setShelves(newShelves);
  }, [books, kindleBooks]);

  useEffect(() => {
    fetchUserBookshelfData();
    fetchKindleBooksData();
  }, [idToken, fetchUserBookshelfData, fetchKindleBooksData]);

  // 通常の本（除籍済みでないもの）
  const activeBooks = useMemo(() => {
    return books.filter(book => book.global_book.RemovedDate === null);
  }, [books]);

  // 棚ごとの冊数（通常の本＋Kindleの本）
  const shelfBookCounts = useMemo(() => {
    const counts: { [key: string]: number } = {};
    activeBooks.forEach(book => {
      const shelfId = book.global_book.GlobalHondanaID || 'unassigned';
      counts[shelfId] = (counts[shelfId] || 0) + 1;
    });
    counts['all'] = activeBooks.length;
    if (kindleBooks && kindleBooks.length > 0) {
      counts['kindle'] = kindleBooks.length;
    }
    return counts;
  }, [activeBooks, kindleBooks]);

//   const handleBookClick = (kanriId: string) => {
//     window.open(`/manifestation/${kanriId}`, '_blank');
//   };
    const handleBookClick = (book: any) => {
        if (book.asin) {
        // Kindle 本の場合は、Amazon の product_url を使ってリンクを開く
        window.open(`https://www.amazon.jp${book.product_url}`, '_blank');
        } else {
        // 紙の本の場合は、既存の処理を実行
        window.open(`/manifestation/${book.global_book?.KANRI_ID || book.KANRI_ID}`, '_blank');
        }
    };
    

  const handleOpenDialog = (bookID: string) => {
    setSelectedBookID(bookID);
  };

  const handleCloseDialog = () => {
    setSelectedBookID(null);
  };

  // フィルター関数：Kindle本の場合は title と authors を利用
  const filterBooks = (book: any) => {
    const searchText = filterText.toLowerCase();
    if (book.asin) {
      return (
        (book.title && book.title.toLowerCase().includes(searchText)) ||
        (book.authors && book.authors.toLowerCase().includes(searchText))
      );
    } else {
      const { MainTitle, Responsibility } = book.book_details;
      return (
        MainTitle.toLowerCase().includes(searchText) ||
        Responsibility.toLowerCase().includes(searchText)
      );
    }
  };

  // ソート関数：Kindle本は acquiredTime を利用
  const sortBooks = (books: any[]) => {
    if (books.length > 0 && books[0].asin) {
      switch (sortCriteria) {
        case 'newest':
          return books.sort((a, b) => b.acquiredTime - a.acquiredTime);
        case 'oldest':
          return books.sort((a, b) => a.acquiredTime - b.acquiredTime);
        default:
          return books;
      }
    }
    // 通常の本の場合
    switch (sortCriteria) {
      case 'newest':
        return books.sort((a, b) => new Date(b.global_book.RegisteredDate).getTime() - new Date(a.global_book.RegisteredDate).getTime());
      case 'oldest':
        return books.sort((a, b) => new Date(a.global_book.RegisteredDate).getTime() - new Date(b.global_book.RegisteredDate).getTime());
      case 'pub-newest':
        return books.sort((a, b) => new Date(b.book_details.PublishingDate).getTime() - new Date(a.book_details.PublishingDate).getTime());
      case 'pub-oldest':
        return books.sort((a, b) => new Date(a.book_details.PublishingDate).getTime() - new Date(b.book_details.PublishingDate).getTime());
      default:
        return books;
    }
  };

  // 選択された棚に応じた本のリスト
  const filteredBooks = useMemo(() => {
    if (selectedShelf === "kindle") {
      return kindleBooks.filter(filterBooks);
    }
    return books
      .filter(book => selectedShelf === "all" || book.global_book.GlobalHondanaID === selectedShelf)
      .filter(filterBooks);
  }, [books, kindleBooks, selectedShelf, filterText]);

  const sortedBooks = useMemo(() => {
    return sortBooks(filteredBooks);
  }, [filteredBooks, sortCriteria]);

  if (isLoading)
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <Typography variant="h6" align="center" gutterBottom>
          本棚を読み込んでいます...
        </Typography>
        <LinearProgress />
      </Box>
    );
  if (error) return <Typography color="error">{error}</Typography>;

  // 合計冊数：通常の本＋Kindle本
  const combinedTotal = totalBooks + (kindleBooks ? kindleBooks.length : 0);

  const getSelectedShelfColor = () => {
    const selected = shelves.find(shelf => shelf.GlobalHondanaID === selectedShelf);
    return selected ? selected.ColorCode : "#808080";
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        全 {combinedTotal} 冊
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>並び替え</InputLabel>
          <Select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value as string)} label="並び替え">
            <MenuItem value="newest">登録順(新しい順)</MenuItem>
            <MenuItem value="oldest">登録順(古い順)</MenuItem>
            <MenuItem value="pub-newest">出版日順(新しい順)</MenuItem>
            <MenuItem value="pub-oldest">出版日順(古い順)</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="フィルター"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="outlined"
          sx={{ flexGrow: 1, minWidth: 200 }}
          InputProps={{
            endAdornment: filterText && <IconButton onClick={() => setFilterText('')}><ClearIcon /></IconButton>
          }}
        />
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '200px', mr: 2, flexShrink: 0 }}>
          <List>
            {shelves.map((shelf) => (
              <ListItem key={shelf.GlobalHondanaID} disablePadding>
                <ListItemButton
                  selected={selectedShelf === shelf.GlobalHondanaID}
                  onClick={() => setSelectedShelf(shelf.GlobalHondanaID)}
                >
                  <ListItemText
                    primary={
                      <>
                        {shelf.HondanaName}
                        <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                          ({shelfBookCounts[shelf.GlobalHondanaID] || 0})
                        </Typography>
                      </>
                    }
                    sx={{
                      '& .MuiListItemText-primary': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: '1.2em',
                        maxHeight: '2.4em'
                      }
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        {/* <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6 }}>
          {sortedBooks.map((book: any) => (
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={book.global_book?.GlobalBookID || book.asin}>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Card
                  onClick={() =>
                    handleBookClick(
                      book.global_book?.KANRI_ID || book.asin || book.KANRI_ID
                    )
                  }
                  sx={{
                    height: '220px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: getSelectedShelfColor(),
                    transition: '0.3s',
                    '&:hover': {
                      boxShadow: 10
                    },
                    padding: '12px',
                    marginBottom: '8px'
                  }}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <CardMedia
                    component="img"
                    sx={{
                        height: '180px',
                        objectFit: 'contain',
                        backgroundColor: getSelectedShelfColor(),
                        borderRadius: '4px'
                    }}
                    image={
                        book.asin
                        ? (() => {
                            if (book.image_url) {
                                // URL例: "https://m.media-amazon.com/images/I/31e+GlhmAML.jpg"
                                // 上記から "31e+GlhmAML" を抽出して新しいURLを生成
                                const match = book.image_url.match(/\/I\/([^\.\/]+)(?:\.jpg)?/);
                                return match && match[1]
                                ? `https://8iyzcyktug.execute-api.us-east-1.amazonaws.com/default/generate_kindle_cover?image_id=${match[1]}`
                                : '/default-kindle-cover.jpg';
                            }
                            return '/default-kindle-cover.jpg';
                            })()
                        : generateCoverUrl(book.book_details)
                    }
                    alt={book.asin ? book.title : book.book_details.MainTitle}
                  />

                  </Box>
                </Card>
                <Box sx={{ mt: 1, textAlign: 'left' }}>
                  {book.asin ? (
                    <>
                      <Typography variant="subtitle2" noWrap>
                        {book.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {book.authors}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2" noWrap>
                        {book.book_details.MainTitle}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {book.book_details.Responsibility}
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      cursor: 'pointer',
                      color: book.lending_status === "Lending" ? '#FB8C00' : '#64B5F6'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog(book.global_book?.GlobalBookID || book.asin);
                    }}
                  >
                    {book.lending_status === "Lending" ? "貸出中" : "貸出可能"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid> */}

        <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6 }}>
        {sortedBooks.map((book: any) => (
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={book.global_book?.GlobalBookID || book.asin}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Card
                onClick={() => handleBookClick(book)}
                sx={{
                    height: '220px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: getSelectedShelfColor(),
                    transition: '0.3s',
                    '&:hover': {
                    boxShadow: 10
                    },
                    padding: '12px',
                    marginBottom: '8px'
                }}
                >
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <CardMedia
                    component="img"
                    sx={{
                        height: '180px',
                        objectFit: 'contain',
                        backgroundColor: getSelectedShelfColor(),
                        borderRadius: '4px'
                    }}
                    image={
                        book.asin
                        ? (() => {
                            if (book.image_url) {
                                // URL例: "https://m.media-amazon.com/images/I/31e+GlhmAML.jpg"
                                // 上記から "31e+GlhmAML" を抽出して新しいURLを生成
                                const match = book.image_url.match(/\/I\/([^\.\/]+)(?:\.jpg)?/);
                                return match && match[1]
                                ? `https://8iyzcyktug.execute-api.us-east-1.amazonaws.com/default/generate_kindle_cover?image_id=${match[1]}`
                                : '/default-kindle-cover.jpg';
                            }
                            return '/default-kindle-cover.jpg';
                            })()
                        : generateCoverUrl(book.book_details)
                    }
                    alt={book.asin ? book.title : book.book_details.MainTitle}
                    />
                </Box>
                </Card>
                <Box sx={{ mt: 1, textAlign: 'left' }}>
                {book.asin ? (
                    <>
                    <Typography variant="subtitle2" noWrap>
                        {book.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {book.authors}
                    </Typography>
                    </>
                ) : (
                    <>
                    <Typography variant="subtitle2" noWrap>
                        {book.book_details.MainTitle}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {book.book_details.Responsibility}
                    </Typography>
                    </>
                )}
                {/* Kindle 本の場合は貸出状況の表示を行わない */}
                {!book.asin && (
                    <Typography
                    variant="caption"
                    sx={{
                        cursor: 'pointer',
                        color: book.lending_status === "Lending" ? '#FB8C00' : '#64B5F6'
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog(book.global_book?.GlobalBookID || book.asin);
                    }}
                    >
                    {book.lending_status === "Lending" ? "貸出中" : "貸出可能"}
                    </Typography>
                )}
                </Box>
            </Box>
            </Grid>
        ))}
        </Grid>

      </Box>
      {selectedBookID && (
        <BookDetailDialog
          open={Boolean(selectedBookID)}
          onClose={handleCloseDialog}
          globalBookID={selectedBookID}
        />
      )}
    </Container>
  );
};

export default UsersBookshelf;
