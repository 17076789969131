//utils/userProfileApi.ts


import axios from 'axios';

const API_URL = 'https://p6718tdt10.execute-api.us-east-1.amazonaws.com/default/bublio-cognito-auth';

const OPEN_API_URL = 'https://cp04osziul.execute-api.us-east-1.amazonaws.com/default/readlinker-userprofile-open';

const API_URL_IMAGE_UPDATE = 'https://moczww63k1.execute-api.us-east-1.amazonaws.com/default/readlinker-profile-image-change';


interface ApiResponse {
    profileImage?: string;
    profileText?: string;
    message?: string;
    preferred_name?: string;
}


export interface UserData {
    profileImage?: string;
    profileText?: string;
    username? :string;
    userSub? :string;
    preferred_name? :string;
}

export const getUserProfileImage = async (userSub: string, idToken?: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_image',
        targetUserSub: userSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile image:', error.message);
        } else {
            console.error('Failed to fetch user profile image:', error);
        }
        return null;
    }
};

export const getUserProfileText = async (userSub: string, idToken?: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_text',
        targetUserSub: userSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile text:', error.message);
        } else {
            console.error('Failed to fetch user profile text:', error);
        }
        return null;
    }
};

export const updateUserProfile = async (
    userSub: string,
    profileImage: File | null,
    profileText: string,
    idToken: string
): Promise<ApiResponse | null> => {

    const data = {
        Operation: 'update_user_profile',
        targetUserSub: userSub,
        profileImage: profileImage,
        profileText: profileText,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        //ここで、{}になっちゃう
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to update user profile:', error.message);
        } else {
            console.error('Failed to update user profile:', error);
        }
        return null;
    }
};

export const getPreferredUsername = async (userSub: string, idToken?: string): Promise<ApiResponse | null> => {

    const data = {
        Operation: 'get_preferred_username',
        targetUserSub: userSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch preferred username:', error.message);
        } else {
            console.error('Failed to fetch preferred username:', error);
        }
        return null;
    }
}

export const update_preferred_username = async (userSub: string, preferred_name: string, idToken: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'update_preferred_username',
        targetUserSub: userSub,
        newPreferredName: preferred_name,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to update preferred username:', error.message);
        } else {
            console.error('Failed to update preferred username:', error);
        }
        return null;
    }
}

export const getUserProfileImageByUsername = async (username: string, idToken: String): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_image_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile image by username:', error.message);
        } else {
            console.error('Failed to fetch user profile image by username:', error);
        }
        return null;
    }
}


export const getUserProfileTextByUsername = async (username: string, idToken: String): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_text_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile text by username:', error.message);
        } else {
            console.error('Failed to fetch user profile text by username:', error);
        }
        return null;
    }
}

export const getPreferredUsernameByUsername = async (username: string, idToken: String): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_preferred_username_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch preferred username by username:', error.message);
        } else {
            console.error('Failed to fetch preferred username by username:', error);
        }
        return null;
    }
}


export const getUserProfileImageByUsernameOpenEndpoint = async (username: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_image_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(OPEN_API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile image by username:', error.message);
        } else {
            console.error('Failed to fetch user profile image by username:', error);
        }
        return null;
    }
}



export const getUserProfileTextByUsernameOpenEndpoint = async (username: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_user_profile_text_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(OPEN_API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch user profile text by username:', error.message);
        } else {
            console.error('Failed to fetch user profile text by username:', error);
        }
        return null;
    }
}


export const getPreferredUsernameByUsernameOpenEndpoint = async (username: string): Promise<ApiResponse | null> => {
    const data = {
        Operation: 'get_preferred_username_by_username',
        targetUsername: username,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(OPEN_API_URL, JSON.stringify(data), { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch preferred username by username:', error.message);
        } else {
            console.error('Failed to fetch preferred username by username:', error);
        }
        return null;
    }
}


export async function uploadImageToLambda(file: File, userSub: string, idToken: string): Promise<string | null> {
    try {
        const reader = new FileReader();
        
        return new Promise((resolve, reject) => {
            reader.onload = async () => {
                const base64Image = reader.result?.toString().split(',')[1]; // Base64データのみ取得

                if (!base64Image) {
                    reject("Error reading file");
                    return;
                }

                // Lambdaエンドポイントに画像をアップロード
                const response = await fetch(API_URL_IMAGE_UPDATE, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`,
                    },
                    body: JSON.stringify({
                        image: base64Image,
                        userSub: userSub,
                        fileName: file.name.split('.')[0] // ファイル名から拡張子を除いた部分を使用
                    }),
                });

                if (response.ok) {
                    const result = await response.json();
                    resolve(result.url); // アップロードされた画像のURLを返す
                } else {
                    reject("Failed to upload image");
                }
            };

            reader.onerror = () => {
                reject("Error reading file");
            };

            reader.readAsDataURL(file);
        });
    } catch (error) {
        console.error('Error uploading image to Lambda:', error);
        return null;
    }
}


export const updateUserProfileWithoutImage = async (
    userSub: string,
    profileText: string,
    idToken: string
): Promise<void> => {
    try {
        const data = {
            Operation: 'update_user_profile',
            targetUserSub: userSub,
            profileText,
        };

        // console.log(data);

        const headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
        };

        if (idToken) {
            headers['Authorization'] = `Bearer ${idToken}`;
        }

        // プロフィールの更新を行うAPIエンドポイント
        const profileUpdateUrl = API_URL

        // プロフィール更新リクエストを送信
        const response = await axios.post(profileUpdateUrl, JSON.stringify(data), { headers });
        
        if (response.status !== 200) {
            throw new Error('Failed to update profile with image');
        }


    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to update user profile with image:', error.message);
        } else {
            console.error('Failed to update user profile with image:', error);
        }
    }
};

// 新しい型定義
interface UserProfileResponse {
    userSub: string;
    preferred_name: string;
    profileImage: string;
    profileText: string;
    username: string;
}

// username から userSub を取得する関数
export const getUserSubFromUsernameCF = async (username: string): Promise<string | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://d2l8a9ua2ipsnz.cloudfront.net/default/${username}`);
        
        if (!response.ok) {
        throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();
        
        // userSub を返す
        return userData.userSub;
    } catch (error) {
        console.error('Error fetching userSub:', error);
        return null;
    }
};

export const getUsernameFromUsersubCF = async (userSub: string): Promise<string | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://16z2rgasn7.execute-api.us-east-1.amazonaws.com/default/user/${userSub}`);
        
        if (!response.ok) {
        throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();

        // console.log(userData);
        
        // username を返す
        return userData.username;
    } catch (error) {
        console.error('Error fetching username:', error);
        return null;
    }
}

export const getUserProfileImageByuserSubCF = async (userSub: string): Promise<string | null> => {
    try {
        // API Gatewayエンドポイントからユーザー情報を取得
        const response = await fetch(`https://16z2rgasn7.execute-api.us-east-1.amazonaws.com/default/user/${userSub}`);
        
        if (!response.ok) {
        throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();
        
        // profileImageのURLからファイル名を抽出
        const profileImageUrl = userData.profileImage;
        const fileName = profileImageUrl.split('/').pop();  // 例: "9c6d30f1-e3aa-47fe-aa47-981bca518d82_20240612_180108.jpg"
        
        if (!fileName) {
        throw new Error('Profile image filename extraction failed');
        }
        
        // CloudFrontのURLを構築
        const cloudFrontUrl = `https://d2bgph94fruh44.cloudfront.net/profile_images/${fileName}`;
        
        // 画像のURLを返す
        return cloudFrontUrl;
    } catch (error) {
        console.error('Error fetching user profile image:', error);
        return null;
    }
};

export const getUserProfileImageByUsernameCF = async (username: string): Promise<string | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://d2l8a9ua2ipsnz.cloudfront.net/default/${username}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();
        
        // profileImageのURLを直接返す
        const profileImageUrl = userData.profileImage;
        
        if (!profileImageUrl) {
            throw new Error('Profile image URL not found');
        }
        
        return profileImageUrl;
    } catch (error) {
        console.error('Error fetching user profile image:', error);
        return null;
    }
};


// username からユーザーのプロフィールテキストを取得する関数
export const getUserProfileTextByUsernameCF = async (username: string): Promise<string | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://d2l8a9ua2ipsnz.cloudfront.net/default/${username}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();
        
        // profileTextを返す
        return userData.profileText;
    } catch (error) {
        console.error('Error fetching user profile text:', error);
        return null;
    }
};

// username からユーザーの preferred_name を取得する関数
export const getUserPreferredNameByUsernameCF = async (username: string): Promise<string | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://d2l8a9ua2ipsnz.cloudfront.net/default/${username}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserProfileResponse = await response.json();
        
        // preferred_name を返す
        return userData.preferred_name;
    } catch (error) {
        console.error('Error fetching user preferred name:', error);
        return null;
    }
};


// username からユーザー情報を取得する関数
export const getUserInfoByUsernameCF = async (username: string): Promise<UserData | null> => {
    try {
        // CloudFrontのエンドポイントからユーザー情報を取得
        const response = await fetch(`https://d2l8a9ua2ipsnz.cloudfront.net/default/${username}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserData = await response.json();
        
        // 必要なデータを返す
        return {
            profileImage: userData.profileImage,
            profileText: userData.profileText,
            username: userData.username,
            userSub: userData.userSub,
            preferred_name: userData.preferred_name
        };
    } catch (error) {
        console.error('Error fetching user info by username:', error);
        return null;
    }
};

// userSub からユーザー情報を取得する関数
export const getUserInfoByUsersubCF = async (userSub: string): Promise<UserData | null> => {
    try {
        // API Gatewayエンドポイントからユーザー情報を取得
        const response = await fetch(`https://16z2rgasn7.execute-api.us-east-1.amazonaws.com/default/user/${userSub}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile information');
        }
        
        const userData: UserData = await response.json();
        
        // 必要なデータを返す
        return {
            profileImage: userData.profileImage,
            profileText: userData.profileText,
            username: userData.username,
            userSub: userData.userSub,
            preferred_name: userData.preferred_name
        };
    } catch (error) {
        console.error('Error fetching user info by userSub:', error);
        return null;
    }
};
