// // utils/generateCoverUrl.ts

// interface PartialBookDetail {
//   MainTitle: string;
//   ContentDetailTitle?: string;
//   TitleOthers?: string;
//   ISBN?: string;
// }

// export const generateCoverUrl = (book: PartialBookDetail): string => {
//   const baseUrl = "https://d29nzmf96r2qm6.cloudfront.net/default/generate_ogp_http_param?title=";
//   const title = `${book.MainTitle}${book.ContentDetailTitle || ''}${book.TitleOthers || ''}`;
//   let imageUrl = `${baseUrl}${encodeURIComponent(title)}`;

//   if (book.ISBN) {
//     imageUrl += `&isbn=${book.ISBN}`;
//   }

//   return imageUrl;
// };


// utils/generateCoverUrl.ts

interface PartialBookDetail {
  MainTitle: string;
  ContentDetailTitle?: string;
  TitleOthers?: string;
  ISBN?: string;
  // Kindle本用のフィールドを追加
  isKindle?: boolean;
  kindle_image_id?: string;  // Amazon画像ID (例: "41vE2nlc2jL")
}

/**
 * 本のカバー画像URLを生成する関数
 * @param book 本の詳細情報
 * @returns カバー画像のURL
 */
export const generateCoverUrl = (book: PartialBookDetail): string => {
  // Kindle本の場合は画像IDを使用
  if (book.isKindle && book.kindle_image_id) {
    // タブレットフレーム付きの画像を生成するAPIエンドポイント（画像IDのみ受け取る）
    const kindleCoverBaseUrl = "https://d29nzmf96r2qm6.cloudfront.net/default/generate_kindle_cover?image_id=41CCgH3H0bL";
    return `${kindleCoverBaseUrl}${book.kindle_image_id}`;
  }
  
  // 従来の紙の本用の処理（変更なし）
  const baseUrl = "https://d29nzmf96r2qm6.cloudfront.net/default/generate_ogp_http_param?title=";
  const title = `${book.MainTitle}${book.ContentDetailTitle || ''}${book.TitleOthers || ''}`;
  let imageUrl = `${baseUrl}${encodeURIComponent(title)}`;

  if (book.ISBN) {
    imageUrl += `&isbn=${book.ISBN}`;
  }

  return imageUrl;
};