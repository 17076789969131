import React, { useState, useEffect } from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { userPool } from '../services/cognitoConfig'; 
import { useAuth } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { User } from '../types/types';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from '../assets/images/readLinker_mini_logo_128.png';
import '../css/LoginPage.css';

const LoginPage: React.FC = () => {
    useEffect(() => {
        document.body.classList.add('login-page');
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const { login } = useAuth(); 
    const navigate = useNavigate();

    const handleIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/\s/.test(value)) {
            setError('空白文字は使用できません。');
        } else {
            setError(null);
            setIdentifier(value);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/\s/.test(value)) {
            setError('空白文字は使用できません。');
        } else {
            setError(null);
            setPassword(value);
        }
    };

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);

        if (!identifier || !password) {
            setError('ユーザー名とパスワードを入力してください。');
            return;
        }

        const authenticationDetails = new AuthenticationDetails({
            Username: identifier,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: identifier,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                // console.log('ログイン成功', session);
                const idToken = session.getIdToken().getJwtToken();
                const refreshToken = session.getRefreshToken().getToken();
                sessionStorage.setItem("idToken", idToken);
                sessionStorage.setItem("refreshToken", refreshToken);
                // console.log('idToken:', idToken);

                const userInfo: User = {
                    username: session.getIdToken().payload.name, 
                    sub: session.getIdToken().payload.sub,
                    email: session.getIdToken().payload.email,
                };
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                login(userInfo);
                
                setTimeout(() => {
                    navigate('/');
                }, 100);
            },
            onFailure: (err) => {
                console.error('ログイン失敗', err);
                setError('ログインに失敗しました。ユーザー名またはパスワードが正しくありません。');
            },
        });
    };

    return (
        <div className="login-page">
            <Container maxWidth="xs">
                <Box
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        color: 'white',
                        py: 20,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <img src={logo} alt="ReadLinker logo" style={{ width: '128px', height: '128px' }} />
                        <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
                            すべての本が、ここに。
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            今すぐ参加しましょう。
                        </Typography>
                        <Box component="form" onSubmit={onSubmit} sx={{ mt: 3, width: '100%' }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="identifier"
                                label="ユーザー名"
                                name="identifier"
                                autoComplete="identifier"
                                autoFocus
                                value={identifier}
                                onChange={handleIdentifierChange}
                                sx={{ backgroundColor: 'white', borderRadius: 1 }}
                            />
                            {error && <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>{error}</Typography>}
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={handlePasswordChange}
                                sx={{ backgroundColor: 'white', borderRadius: 1 }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                ログイン
                            </Button>
                        </Box>
                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                            アカウント作成は<Link to="/about-readlinker" style={{ color: 'white' }}>モバイルアプリ</Link>で行います
                        </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        © 2023-2024 ADA LLC.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
};

export default LoginPage;
